import React from "react";

const BlankFillStarIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M3.58253 18.5625C3.48503 19.1175 4.03253 19.5513 4.51503 19.3038L10.0025 16.4838L15.4888 19.3038C15.9713 19.5513 16.5188 19.1175 16.4213 18.5638L15.3838 12.6513L19.7863 8.45628C20.1988 8.06378 19.9863 7.34628 19.4338 7.26878L13.3113 6.39878L10.5813 0.990026C10.5293 0.880387 10.4472 0.787755 10.3447 0.722891C10.2421 0.658028 10.1232 0.623596 10.0019 0.623596C9.88055 0.623596 9.7617 0.658028 9.65914 0.722891C9.55658 0.787755 9.47453 0.880387 9.42253 0.990026L6.69253 6.40003L0.570026 7.27003C0.0187758 7.34753 -0.194974 8.06503 0.216276 8.45753L4.62003 12.6525L3.58253 18.565V18.5625ZM9.71378 15.1038L5.10628 17.4713L5.97378 12.525C5.9941 12.4112 5.98615 12.2941 5.95062 12.184C5.91509 12.0739 5.85308 11.9743 5.77003 11.8938L2.13753 8.43128L7.20253 7.71128C7.30741 7.69544 7.40689 7.65441 7.49244 7.59172C7.578 7.52902 7.64708 7.44652 7.69378 7.35128L10 2.77878L12.3088 7.35128C12.3555 7.44652 12.4246 7.52902 12.5101 7.59172C12.5957 7.65441 12.6951 7.69544 12.8 7.71128L17.865 8.43003L14.2325 11.8925C14.1493 11.9731 14.0871 12.073 14.0516 12.1833C14.0161 12.2936 14.0082 12.411 14.0288 12.525L14.8963 17.4713L10.2888 15.1038C10.1997 15.0578 10.1009 15.0339 10.0007 15.0339C9.90041 15.0339 9.80162 15.0578 9.71253 15.1038H9.71378Z'
          fill='#FFDC26'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlankFillStarIcon;

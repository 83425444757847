import React from "react";

const HalfFillStarIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M6.69249 6.39875L9.42249 0.99C9.47492 0.881192 9.55684 0.789292 9.65894 0.724762C9.76104 0.660232 9.87921 0.625667 9.99999 0.625C10.2287 0.625 10.4575 0.74625 10.5812 0.99L13.3112 6.39875L19.4337 7.26875C19.5863 7.29274 19.726 7.36864 19.8292 7.48363C19.9323 7.59863 19.9927 7.74569 20 7.9C20.0087 8.00273 19.9941 8.10611 19.9573 8.20242C19.9205 8.29874 19.8625 8.3855 19.7875 8.45625L15.3837 12.6513L16.4212 18.5637C16.5187 19.1175 15.9712 19.5512 15.4887 19.3037L9.99999 16.4838L4.51374 19.3037C4.45635 19.3335 4.39484 19.3546 4.33124 19.3663C3.90374 19.4413 3.49624 19.0487 3.58124 18.5637L4.61874 12.6513L0.216234 8.45625C0.147791 8.3915 0.0933887 8.31337 0.0564034 8.22671C0.0194182 8.14005 0.000639957 8.04672 0.00123439 7.9525C0.0012994 7.8193 0.0380611 7.68868 0.107484 7.575C0.156733 7.49259 0.223858 7.42228 0.303907 7.36928C0.383956 7.31627 0.474885 7.28192 0.569984 7.26875L6.69249 6.39875V6.39875ZM9.99999 15.0338C10.1009 15.0333 10.2004 15.0573 10.29 15.1038L14.8975 17.4713L14.03 12.525C14.0095 12.4112 14.0173 12.2942 14.0526 12.1841C14.0879 12.0741 14.1497 11.9744 14.2325 11.8938L17.8662 8.43125L12.8012 7.71125C12.6964 7.69541 12.5969 7.65439 12.5113 7.59169C12.4258 7.52899 12.3567 7.44649 12.31 7.35125L10.0012 2.77875L9.99999 2.7825V15.0325V15.0338Z'
          fill='#FFDC26'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HalfFillStarIcon;

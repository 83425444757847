import React from "react";

const ArrowIcon = () => {
  return (
    <svg
      width='24'
      height='20'
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5862 0.586306C12.9613 0.210895 13.4699 0 14.0002 0C14.5306 0 15.0392 0.210895 15.4143 0.586306L23.4144 8.59632C23.7894 8.97184 24 9.4811 24 10.0121C24 10.5431 23.7894 11.0523 23.4144 11.4279L15.4143 19.4379C15.0371 19.8026 14.5318 20.0045 14.0074 19.9999C13.483 19.9954 12.9814 19.7848 12.6106 19.4135C12.2398 19.0422 12.0294 18.5399 12.0249 18.0149C12.0203 17.4898 12.2219 16.984 12.5862 16.6063L17.1723 12.0146H2.00004C1.46959 12.0146 0.960877 11.8036 0.585797 11.4281C0.210718 11.0525 0 10.5432 0 10.0121C0 9.48099 0.210718 8.97165 0.585797 8.59611C0.960877 8.22056 1.46959 8.00959 2.00004 8.00959H17.1723L12.5862 3.41785C12.2113 3.04232 12.0006 2.53307 12.0006 2.00208C12.0006 1.47108 12.2113 0.961831 12.5862 0.586306Z'
        fill='#998CAC'
      />
    </svg>
  );
};

export default ArrowIcon;

import React from "react";

function TelegramIcon() {
  return (
    <svg
      width='33'
      height='28'
      viewBox='0 0 33 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.7726 0.184107L1.5409 11.5883C-0.454044 12.399 -0.442503 13.5249 1.17488 14.0269L8.67982 16.3955L26.044 5.31158C26.8651 4.80617 27.6153 5.07806 26.9986 5.63184L12.9302 18.4772H12.9269L12.9302 18.4789L12.4125 26.3052C13.1709 26.3052 13.5056 25.9532 13.931 25.5379L17.5763 21.9517L25.1587 27.6179C26.5568 28.3969 27.5609 27.9966 27.9087 26.3085L32.8862 2.57604C33.3957 0.509369 32.1064 -0.426386 30.7726 0.184107Z'
        fill='#FAF9FC'
      />
    </svg>
  );
}

export default TelegramIcon;

import React from "react";

const CommentIcon = () => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 3.125V15.625C25 16.4538 24.6708 17.2487 24.0847 17.8347C23.4987 18.4208 22.7038 18.75 21.875 18.75H14.0625L6.25 25V18.75H3.125C2.2962 18.75 1.50134 18.4208 0.915291 17.8347C0.32924 17.2487 0 16.4538 0 15.625V3.125C0 2.2962 0.32924 1.50134 0.915291 0.915291C1.50134 0.32924 2.2962 0 3.125 0H21.875C22.7038 0 23.4987 0.32924 24.0847 0.915291C24.6708 1.50134 25 2.2962 25 3.125ZM7.8125 7.8125H4.6875V10.9375H7.8125V7.8125ZM10.9375 7.8125H14.0625V10.9375H10.9375V7.8125ZM20.3125 7.8125H17.1875V10.9375H20.3125V7.8125Z'
        fill='#938D9E'
      />
    </svg>
  );
};

export default CommentIcon;

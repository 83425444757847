import React from "react";

const PeopleIcon = () => {
  return (
    <svg
      width='25'
      height='27'
      viewBox='0 0 25 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 10.8C13.9208 10.8 15.2834 10.2311 16.2881 9.21838C17.2927 8.20568 17.8571 6.83217 17.8571 5.4C17.8571 3.96783 17.2927 2.59432 16.2881 1.58162C15.2834 0.568927 13.9208 0 12.5 0C11.0792 0 9.71659 0.568927 8.71193 1.58162C7.70727 2.59432 7.14286 3.96783 7.14286 5.4C7.14286 6.83217 7.70727 8.20568 8.71193 9.21838C9.71659 10.2311 11.0792 10.8 12.5 10.8ZM0 27C-2.44606e-08 25.3453 0.323322 23.7069 0.951506 22.1782C1.57969 20.6495 2.50043 19.2605 3.66116 18.0905C4.8219 16.9204 6.19989 15.9923 7.71646 15.3591C9.23303 14.7259 10.8585 14.4 12.5 14.4C14.1415 14.4 15.767 14.7259 17.2835 15.3591C18.8001 15.9923 20.1781 16.9204 21.3388 18.0905C22.4996 19.2605 23.4203 20.6495 24.0485 22.1782C24.6767 23.7069 25 25.3453 25 27H0Z'
        fill='#625776'
      />
    </svg>
  );
};

export default PeopleIcon;

import React from "react";

const LikeIcon = () => {
  return (
    <svg
      width='29'
      height='25'
      viewBox='0 0 29 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.11631 2.22738C3.4718 0.859593 5.30998 0.0912122 7.22664 0.0912122C9.14329 0.0912122 10.9815 0.859593 12.337 2.22738L14.4548 4.36328L16.5727 2.22738C17.2395 1.53054 18.037 0.974714 18.9189 0.592338C19.8008 0.209962 20.7493 0.00869371 21.709 0.000275473C22.6688 -0.00814276 23.6206 0.176459 24.5089 0.543308C25.3972 0.910157 26.2042 1.45191 26.8829 2.13695C27.5616 2.82199 28.0983 3.6366 28.4617 4.53325C28.8252 5.4299 29.0081 6.39063 28.9997 7.35939C28.9914 8.32815 28.792 9.28553 28.4132 10.1757C28.0343 11.0658 27.4837 11.8709 26.7933 12.5439L14.4548 25L2.11631 12.5439C0.761238 11.1757 0 9.32028 0 7.38565C0 5.45101 0.761238 3.59558 2.11631 2.22738Z'
        fill='#938D9E'
      />
    </svg>
  );
};

export default LikeIcon;
